export default [
  {
    key: 'lotteryId',
    label: 'field.lotteryName',
    type: '',
    cols: 3,
  },
  {
    key: 'lotteryTimeId',
    label: 'field.lotteryTime',
    type: '',
    cols: 3,
  },
  {
    key: 'post',
    label: 'field.channel',
    type: '',
    cols: 3,
  },
];